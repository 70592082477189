<div class="position-fixed top-0 bottom-0 start-0 end-0"
     style="z-index: 2147483647; background-color: rgba(250, 246, 235, 0.5)" *ngIf="IsLoading">
  <div class="d-flex align-items-center position-absolute top-0 bottom-0 start-0 end-0 w-100">
    <div class="d-flex justify-content-center w-100">
      <div class="loader">

      </div>
    </div>
  </div>
</div>
<app-navbar></app-navbar>
<router-outlet></router-outlet>
