  <!-- Responsive navbar-->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark page-layout " style="background-color: #0B2D71!important; height: 35px; ">
  <div>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">


      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <ng-container *ngFor="let m of menus">
          <li style="padding-right: 30px;" class="nav-item" id="{{m.name}}">
            <a [ngClass]="{'selected-tab':m.menuId==selectedMenuId}" class="nav-link cursor-pointer active" (click)="OnNavClick(m.menuId)" aria-current="page">
              {{m.name}}
            </a>

          </li>

        </ng-container>



      </ul>


    </div>
  </div>
</nav>


<nav class="navbar navbar-expand-lg navbar-dark bg-dark page-layout " style="background-color: #e8e8e8!important; ">
  <div *ngIf="currentUserProfile" style="margin-left: 10px;">

    Welcome, <strong><em>{{displayName}}  </em></strong>

  </div>
</nav>
