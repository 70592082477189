import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class PermissionsService {

    private dataSource = new BehaviorSubject('');
    currentData = this.dataSource.asObservable();

  constructor() {
    //This is empty
  }

    set(data: any) {
        this.dataSource.next(data)
    }

}
