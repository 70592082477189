import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {

  constructor() {
    //This is empty
  }

  get(key: any) {
    return sessionStorage.getItem(key);
  }

  set(key: any, value: any) {
    sessionStorage.setItem(key, value);
  }

  remove(key: any) {
    sessionStorage.removeItem(key);
  }
}
