import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountService } from './account.service';
import { Employee } from '../models/employee';
import { Message } from '../models/message';

@Injectable({
  providedIn: 'root',
})

export class EmployeeService {

  rootUrl: string;
  noAuthHeader: any;
  constructor(private http: HttpClient, private accountService: AccountService) {
    this.rootUrl = accountService.rootUrl;
    this.noAuthHeader = accountService.noAuthHeader;
  }

  getAllEmployee(): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get(`${this.rootUrl}/api/employee`, { headers });
  }

  getUserProfile(ProvId: string): Observable<any> {
    return this.http.get(`${this.rootUrl}/api/Account/userprofile1/${ProvId}`);
  }

  getEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.rootUrl + '/api/Employee/GetEmployees');
  }

  getEmployeeByCAI(userCAI: string): Observable<Employee> {
    return this.http.get<Employee>(this.rootUrl + '/api/Employee/GetEmployeeByCAI?userCAI=' + userCAI);
  }

  getEmployeeDetail(identity: any): Observable<Employee> {
    return this.http.get<Employee>(this.rootUrl + '/api/Employee/GetEmployeeDetail?identity=' + identity);
  }

  getEmployeePayDetail(employeeID: number): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/api/Employee/GetEmployeePayDetail?employeeID=' + employeeID);
  }

  getDigitalSign(currentUserCAI: string): Observable<Message> {
    return this.http.get<Message>(this.rootUrl + '/api/Employee/GetDigitalSign?currentUserCAI=' + currentUserCAI);
  }

  private handleError(error: any) {
    
    return error(error);
  }
}
