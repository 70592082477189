import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http: HttpService) { }

  getAllMenu(): Observable<any> {
    return this.http.get(`${environment.baseURL}/api/Application/GetAllMenu`);
  }

}
