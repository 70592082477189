import { Injectable } from '@angular/core';
import {HttpClientModule,HttpResponse} from '@angular/common/http';
import { Observable } from "rxjs";
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './sessionStorage.service';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private _apiBaseURL = "http://localhost:57113/api/"
    private _apiURL = "";
    private _apiName = "";

    constructor(private _date: DatePipe,private http: HttpClientModule, private localStorageService: LocalStorageService, private sessionStorageService: SessionStorageService) {
      //This is empty
    }

    getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
                ,'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Expose-Headers': '*'
            })
        };
        return httpOptions;
    }

    getAuthKeys() {
        return {
            sessionID: this.localStorageService.get("sessionID"),
            token: this.localStorageService.get("token")
        }

    }

    getNativeWindow() {
        return window;
    }

   

    clearLocalStorage() {
        this.localStorageService.remove("userToken");
        this.localStorageService.remove("userDetails");
    }

    removeUserCriterias() {
        this.localStorageService.remove('userToken');
    }

    getlocalStorageValueByKey(key: string) {
        return this.localStorageService.get(key);
    }

    setlocalStorageValueByKey(key: string, value: any) {
        this.localStorageService.set(key, value);
    }

    getSessionStorageValueByKey(key: string) {
        return this.sessionStorageService.get(key);
    }
    deletesessionsoragevaluebykey(key:string){
        this.sessionStorageService.remove(key);
    }

    setSessionStorageValueByKey(key: string, value: any) {
        this.sessionStorageService.set(key, value);
    }

    removelocalStorageValueByKey(key: string) {
        this.localStorageService.remove(key);
    }

    getCurrentUser(): any {
        let details = JSON.parse(this.localStorageService.get('userDetails') as string);
        return details;
    }

    private handleError(error: HttpResponse<any>) {
        return (error.body);
    }

    getDateformat(dateValue: any):any{
        if(dateValue!=null && dateValue!=undefined){
       
        let temp: number[] = dateValue.split('-').map(Number);
        let res: Date = new Date(Date.UTC(temp[2],temp[1] - 1, temp[0]));

        let d = new Date(res.toLocaleDateString(undefined,{ year: "numeric",
         month: "2-digit",
         day: "2-digit",}));
       
        let dateformat = this._date.transform(d, 'MMM dd, y');
        
        return dateformat;}
    }

    getformattedDate(dateValue: any):any
    { if(dateValue!=null && dateValue!=undefined)
     {
        let dateformat = this._date.transform(dateValue, 'MMM dd, y');
        return dateformat;
     }
    }

    getDate(dateValue: any):any
    { if(dateValue!=null && dateValue!=undefined)
     {
        let dateformat = this._date.transform(dateValue, 'yyyy-MM-dd');
        return dateformat;
     }
    }
}
