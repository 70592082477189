import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationService } from './main-app/services/application.service';
import { DataTransferService } from './main-app/services/data-transfer.service';
import { Subject, Subscription } from 'rxjs';
import { LoaderService } from './main-app/services/loader.service';
import { CalAngularService } from '@cvx/cal-angular';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';


// Registering Syncfusion license key
registerLicense('Mgo+DSMBMAY9C3t2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bdERiUX9bc3dQQGFa');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = '';

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  loaderChangeSubject: Subscription;
  IsLoading: boolean = false;

  constructor(private loaderService: LoaderService, private applicationService: ApplicationService,
    private dataTransferService: DataTransferService, private titleService: Title,
    private changeDetector: ChangeDetectorRef,
    private authService: CalAngularService,
  ) {
    this.titleService.setTitle(`SASBU SIM/LB Tool - ${environment.name}`);

    this.loaderChangeSubject = this.loaderService.triggerLoaderChange
      .subscribe(data => {
        if (data) {
          this.IsLoading = data.value;
          this.changeDetector.detectChanges();
        }
      }, _err => {
        // This is intentional
      });
  }

  ngOnInit(): void {
    this.getAllMenu();

  }

  ngOnDestroy(): void {
    this.loaderChangeSubject.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getAllMenu() {

    this.applicationService.getAllMenu().subscribe(res => {
      if (res) {
        this.dataTransferService.triggerMenu.next({ value: res });
      }
    }, err => { });

  }

}

